/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.small {
    font-size: 0.8em;
}

#dlogo {
    padding: 20px;
    height: 120px;
    background-image: linear-gradient(#007cba, #005a87);
    margin-bottom: 30px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

#dfooter {
    padding: 20px;
    background-image: linear-gradient(#005a87, #007cba);
    margin-top: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

#dfooter a {
    color: #fff;
}

#dfooter a:hover {
    color: #fff;
    text-decoration: underline;
}

#dfooter h6 {
    color: #fff;
    font-size: 0.9em;
}

#dlogo img {
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
}

#dcompanytype {
    margin-top: 40px;
}

#dcompanytype p {
    padding-left: 20px;
}


fieldset {
    margin-bottom: 30px;
}

fieldset div {
    clear: both;
}


fieldset div label {
    width: 40%;
    text-align: right;
    padding-right: 10px;
    color: #005a87;
    padding-bottom: 5px;
}

fieldset div label[for=address] {
    vertical-align: top;
}


fieldset div input,
fieldset div textarea {
    text-align: left;
    padding-left: 10px;
    outline: 0px;
    border: 0px;
    border-bottom: 1px solid #bbb;
}

fieldset div textarea {
    resize: none;
}

fieldset div div.input{
    width: 60%;
    float: right;
    padding-bottom: 10px;
}

fieldset div div.input label {
    width: 100%;
    text-align: left;
    padding-right: 0px;
    color: #000;
    padding-bottom: 0px;
}

.hinput {
    height: 36px;
}

.customSelect {
    width: 45%;
}

.customSelect div {
    border-color: #bbb;
}

.wXL{
    width: 500px;
}

.wL {
    width: 395px;
}

.wXS {
    width: 100px;
}

.modalw {
    width: 65vw; 
    min-width: 850px;
    max-width: 65vw;
}

.miframe {
    height: 50vh !important;
    
    border: 0px;
    outline: 0px;
}


fieldset h5 {
    margin: 25px 0px 25px 0px;
}

#dacceptance {
    margin-top: 20px;
    margin-bottom: 20px;
}

#drecaptcha{
    width: 60%;
    float: right;
    margin-bottom: 30px;
}

.link {
    color: #005a87;
    cursor: hand;
}

.link:hover {
    text-decoration: underline;
}


.erro {
    font-size: 0.8em;
    color: #c00;
    padding-left: 10px;
}

.margB {
    margin-bottom: 10px;
}

.margB1R {
    margin-bottom: 1rem;
}

.underline {
    text-decoration: underline;
}

.required {
    font-size: 0.8em;
    color: #c00;
}

.red {
    font-size: 0.8em;
    color: #c00;
}

.grey {
    color: #777;
}

.green {
    color: #008000;
}

.blue {
    color: #005a87;
}

.black {
    color: #000;
}

.white {
    color: #fff;
}